export const toEECItemsMapper = (products) => {
    return products.map((product, i) => {
        return {
            item_name: product.name,
            item_id: product.productId,
            price: product.price,
            item_brand: product.vendor,
            item_category: product.type,
            quantity: product.qty || 1,
            index: i + 1,
        };
    });
};

export default async function useDataLayer (event, products, list) {
    let data = null;
    if (event === 'trackProductImpressions') {
        event = 'view_item_list';
        data = {
            ecommerce: {
                currencyCode: 'EUR',
                items: toEECItemsMapper(products),
            },
        };
    }
    if (event === 'productClick') {
        event = 'select_item';
        data = {
            event: 'select_item',
            ecommerce: {
                click: {
                    actionField: { list, },
                    products: toEECItemsMapper(products),
                },
            },
        };
    }
    if (event === 'productDetail') {
        event = 'view_item';
        data = {
            ecommerce: {
                detail: {
                    products: toEECItemsMapper(products),
                },
            },
        };
    }
    if (event === 'addToCart') {
        event = 'add_to_cart';
        data = {
            ecommerce: {
                items: toEECItemsMapper(products),
            },
        };
    }
    if (event === 'removeFromCart') {
        event = 'remove_from_cart';
        data = {
            ecommerce: {
                items: toEECItemsMapper(products),
            },
        };
    }
    if (event === 'beginCheckout') {
        event = 'begin_checkout';
        data = {
            ecommerce: {
                items: toEECItemsMapper(products),
            },
        };
    }

    try {
        setTimeout(() => {
            if (window.dataLayer && data) {
                window.dataLayer.push({ event, ...data, });
            }
        }, 1000);
    } catch (error) {
        console.error(error);
    }
}
