import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../theme';
import richContentMapper from '../utils/richContentMapper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    text: theme.typography.body1,
}));

const replaceStringContents = (string) => {
    const find = '<br/><br/>';
    const re = new RegExp(find, 'g');
    string = string.replace(re, '');
    return string;
};

export default function RichContent (props) {
    const classes = useStyles(theme);
    let html = richContentMapper(props.content);

    const textStyle = {
        textAlign: props.centerText ? 'center' : 'left',
        ...props.textStyles,
        display: 'inline-block',
        width: '100%',
    };

    if (props.leadText) textStyle.fontSize = '1.3rem';
    if (props.smallText) textStyle.fontSize = '0.75rem';

    if (props.removeNewLineBreaks) {
        html = replaceStringContents(html);
    }

    return (
        <Container style={props.containerStyles || {}}>
            <div className={classes.root} style={props.styles || {} } id={(props.name && encodeURI(props.name)) }>
                <Grid container item xs={12}>
                    <div className={classes.text} style={textStyle} dangerouslySetInnerHTML={{ __html: html, }} />
                </Grid>
            </div>
        </Container>
    );
}
