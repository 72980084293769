import { Checkout, DiscountApplicationsEntity } from '../../types/ShopifyCheckout';

const fetchShopifyCheckout = async (shopifyCheckoutToken: string) => {
    try {
        const res = await fetch('/api/shop/getCheckout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                checkoutId: shopifyCheckoutToken
            })
        }).then((r) => r.json());
        return res.checkout as Checkout;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const createShopifyCheckout = async (products: any[]) => {
    try {
        const res = await fetch('/api/shop/createCheckout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                products,
            }),
        }).then(r => r.json());
        return res.id;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addDiscountCodeToCart = async (shopifyCheckoutToken: string, discountCode: string) => {
    try {
        let checkoutId;

        // create checkout if no checkout exists
        if (!shopifyCheckoutToken) {
            const res = await createShopifyCheckout([]);
            checkoutId = res.id;
        } else {
            checkoutId = shopifyCheckoutToken;
        }

        const addedDiscountCode = await fetch('/api/shop/addDiscountCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                checkoutId,
                discountCode,
            }),
        }).then(r => r.json());

        return addedDiscountCode;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const removeDiscountCodesFromCart = async (shopifyCheckoutToken: string) => {
    try {
        const addedDiscountCode = await fetch('/api/shop/removeDiscountCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                checkoutId: shopifyCheckoutToken,
            }),
        }).then(r => r.json());
        return addedDiscountCode;
    } catch (error) {
        console.error(error);
        return null;
    }
};

/**
 * Add / Remove the product lover discount code
 */
const applyProductLoverDiscountCode = async (shopifyCheckoutToken: string, productsInCart: number, discounts: DiscountApplicationsEntity[]) => {
    // If there is any product lover discount code, remove it
    if (discounts && discounts.length > 0) {
        if (discounts.find((d) => d.code.includes('TheCode Product Lover'))) {
            await removeDiscountCodesFromCart(shopifyCheckoutToken);
        }
    }
    // If there are 2 products, apply the discount "TheCode Product Lover 10%"
    if (productsInCart === 2) {
        await addDiscountCodeToCart(shopifyCheckoutToken, 'TheCode Product Lover 10%');
    }
    // If there are more than 3 products, apply the discount "TheCode Product Lover 20%"
    if (productsInCart > 2) {
        await addDiscountCodeToCart(shopifyCheckoutToken, 'TheCode Product Lover 20%');
    }

    const checkout = await fetchShopifyCheckout(shopifyCheckoutToken);
    return checkout;
};

export { fetchShopifyCheckout, applyProductLoverDiscountCode, addDiscountCodeToCart, createShopifyCheckout };
