import React from 'react';
import { Container, useMediaQuery } from '@material-ui/core';

import theme from '../theme';

const ImageWrapper = (props) => {
    let {
        imageName,
        alt = '',
        padding = 0,
        margin = 0,
        textAlign = 'inherit',
        imageStyles = {},
        divStyles = {},
        isProduct = false,
        component,
    } = props;

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    // if there is a component prop, then it is a contentful component
    if (component) {
        imageName = isDesktop ? props.image : props.imageMobile;
        alt = props.imageAltText;
        if (props.centerImage) {
            margin = 'auto';
            textAlign = 'center';
        }
    }

    const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';
    const imageUrl = (isProduct ? '' : base) + `${imageName && imageName.replace(/\s/g, '')}`;
    const style = { maxWidth: '100%', maxHeight: '100%', ...imageStyles, };

    const ImageComponent = () => (
        <div key="wrapper" id="wrapper" style={{ padding, margin, textAlign, ...divStyles, }}>
            <img
                alt={alt}
                src={imageUrl}
                style={style}
            />
        </div>
    );

    if (component) {
        return (
            <Container>
                <ImageComponent />
            </Container>
        );
    }

    return <ImageComponent />;
};

export default React.memo(ImageWrapper);
