import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const convertListElements = nodes => nodes.map((node) => {
    node.nodeType = 'text';
    return node.content;
}).flat();

const replaceStringContents = (string) => {
    string = string.replace(/>,</g, '');
    string = string.replace('<br/><br/><ul>', '<ul>');
    string = string.replace('<br/><br/><ol>', '<ol>');
    return string;
};

const options = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h1 class="MuiTypography-h1">${text.value}</h1>`);
            } else {
                return `<h1 class="MuiTypography-h1">${node.value}</h1>`;
            }
        },
        [BLOCKS.HEADING_2]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h2 class="MuiTypography-h2">${text.value}</h2>`);
            } else {
                return `<h2 class="MuiTypography-h2">${node.value}</h2>`;
            }
        },
        [BLOCKS.HEADING_3]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h3 class="MuiTypography-h3">${text.value}</h3>`);
            } else {
                return `<h3 class="MuiTypography-h3">${node.value}</h3>`;
            }
        },
        [BLOCKS.HEADING_4]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h4 class="MuiTypography-h4">${text.value}</h4>`);
            } else {
                return `<h4 class="MuiTypography-h4">${node.value}</h4>`;
            }
        },
        [BLOCKS.HEADING_5]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h5 class="MuiTypography-h5">${text.value}</h5>`);
            } else {
                return `<h5 class="MuiTypography-h5">${node.value}</h5>`;
            }
        },
        [BLOCKS.HEADING_6]: (node) => {
            if (node.content) {
                return node.content.map(text => `<h6 class="MuiTypography-h6">${text.value}</h6>`);
            } else {
                return `<h6 class="MuiTypography-h6">${node.value}</h6>`;
            }
        },
        [BLOCKS.LIST_ITEM]: (node, next) => `<li style="font-size: 1rem; word-wrap: break-word;" class="MuiTypography-body1">${next(convertListElements(node.content))}</li>`,
    },
    renderMark: {
        [MARKS.ITALIC]: text => `<p class="MuiTypography-subtitle1">${text}</p>`,
    },
};

const richContentMapper = (richContentElement) => {
    try {
        let html = '';

        richContentElement.content.forEach((element, i) => {
            let isList = null;
            let addSpace = true;

            if (element.nodeType && element.nodeType.includes('heading')) {
                addSpace = false;
                element.content = element.content.map((e) => {
                    e.nodeType = element.nodeType;
                    return e;
                });
            }

            if (element.nodeType && element.nodeType.includes('ordered-list')) {
                html = html + '<ol>';
                isList = '</ol>';
                addSpace = false;
            } else if (element.content[0].nodeType.includes('list-item')) {
                html = html + '<ul>';
                isList = '</ul>';
                addSpace = false;
            }

            html = html + documentToHtmlString(element, options);

            if (isList) {
                html = html + isList;
            }

            if (i !== richContentElement.content.length - 1 && addSpace) {
                html = html + '<br/><br/>';
            }
        });

        html = replaceStringContents(html);

        if (!html) {
            console.error('No html', richContentElement);
        }

        return html;
    } catch (error) {
        console.error({ error, richContentElement, });
        return '';
    }
};

export default richContentMapper;
